/* eslint-disable */
import { makeStyles } from 'tss-react/mui';
import { styled } from '@mui/material/styles';
import { Box, Dialog, DialogContent } from '@mui/material';
import Button from '@mui/material/Button';
import React, { useState } from 'react';
import plusIcon from "../assets/plus_icon.png";
import SubscriptionPopup from '../views/Settings/SubscriptionPopup';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const PREFIX = 'OverlaySubscription';

const classes = {
  paper: `${PREFIX}-paper`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: { minWidth: '600px' }
}));

const OverlaySubscription = ({ renderButton }) => {

  const [open, setOpen] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [epochTime, setEpochTime] = useState(1692135141);
  const [daysRemaining, setDaysRemaining] = useState(0);


  const handleCloseOverlay = () => {
    setOpen(false);
  };



  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const DefaultButton = () =>{
    return(
      <p variant="text"
        style={{
          paddingBottom: '0px',
        }}
        onClick={handleClickOpen}>

        <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: '0px',
          }}
        >
          {/* <img src={plusIcon} style={{ marginRight: '10px' }} /> */}
          <Button style={{ 
                          textTransform: 'none',
                          fontSize: '13px', 
                          fontWeight: 'bold',
                          display: 'inline-block',
                          // background: '#0173bf',
                          background: 'linear-gradient(to top right, #01e98f, #0173bf)',
                          color: 'white',
                          textAlign: 'center',
                          borderRadius: '40px',
                          height: '40px',
                          width: '200px',
                          ':hover': {
                            background: 'linear-gradient(to top right, #01e98f, #0173bf)', // Maintain gradient on hover
                            // Add any other hover styles here
                          },
                          }}>
            Increase Client Counts 
            <WorkspacePremiumIcon fontSize="medium" style={{ color: "gold"}} />
          </Button>
        </Box>
      </p>
    )
  }

  const ButtonToRender = renderButton ? renderButton(handleClickOpen) : <DefaultButton />;

  return (
    (<Root>
      {ButtonToRender}
      {disabled
        ? (
          <Dialog maxWidth="md" open>
            <DialogContent sx={{ padding: '20px' }}>
            <SubscriptionPopup />
            </DialogContent>
          </Dialog>
        )
        : (
          <Dialog maxWidth="md" onClose={handleClose} open={open}>
            <DialogContent sx={{ padding: '20px' }}>
            <SubscriptionPopup />
            </DialogContent>
          </Dialog>

        )}
    </Root>)
  );
};

export default OverlaySubscription;

/* eslint-disable */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../context/context';
import { setListItemsParsed } from '../helpers';

// Import Chart.js components for configuration
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const BarGraphBoard = (props) => {
  const {
    state: {
      integrations,
      integrationInfo,
      selectedIntegration,
      selectedBoard,
    },
    dispatch,
  } = useContext(AuthContext);

  const [chartData, setChartData] = useState(null);
  const [chartOptions, setChartOptions] = useState(null);

  const canvasRef = useRef(null);
  const chartInstanceRef = useRef(null);

  useEffect(() => {
    // Ensure props.board and props.board.boardData are defined before proceeding
    if (props.board && props.board.boardData) {
      setListItems(props.data, props.board.boardData, props.board.boardFormulas);
    }
  }, [props.board, props.data]);

  const setListItems = (allData, data, formulas) => {
    try {
      let result = setListItemsParsed(allData, data, formulas);

      if (result && result.length > 1) {
        const labels = result.slice(1).map((item) => item[0]); // First column as labels
        const dataValues = result.slice(1).map((item) => item[1]); // Second column as data

        // Set chart data state
        setChartData({
          labels: labels,
          datasets: [
            {
              label: '', // You can set a label if needed
              data: dataValues,
              backgroundColor: '#033dfc',
              borderColor: '#144efd',
              borderWidth: 1,
            },
          ],
        });

        // Set chart options state
        setChartOptions({
          indexAxis: 'x', // For vertical bar chart
          responsive: true,
          maintainAspectRatio: false,
          animation: false,
          plugins: {
            legend: {
              display: false,
              position: 'top',
            },
            title: {
              display: false,
              text: '', // Set title if needed
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  let label = context.dataset.label || '';
                  if (label) {
                    label += ': ';
                  }
                  if (context.parsed.y !== null) {
                    if (props.board.format === '$') {
                      label += '$' + context.parsed.y.toLocaleString();
                    } else {
                      label += context.parsed.y.toLocaleString() + '%';
                    }
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            y: {
              beginAtZero: true,
              ticks: {
                callback: function (value) {
                  if (props.board.format === '$') {
                    return '$' + value.toLocaleString();
                  }
                  return value.toLocaleString() + '%';
                },
                autoSkip: false,
              },
            },
            x: {
              ticks: {
                autoSkip: false,
              },
            },
          },
        });
      } else {
        // Handle case where result is empty or has insufficient data
        setChartData(null);
        setChartOptions(null);
      }
    } catch (error) {
      console.error('Error in setListItems:', error);
      setChartData(null);
      setChartOptions(null);
    }
  };

  useEffect(() => {
    // Ensure Chart.js code runs only on the client side
    if (typeof window !== 'undefined' && canvasRef.current && chartData && chartData.labels && chartOptions) {
      // Destroy existing chart instance if it exists to prevent duplication
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
      }
  
      // Initialize new chart instance
      chartInstanceRef.current = new ChartJS(canvasRef.current, {
        type: 'bar',
        data: chartData,
        options: chartOptions,
      });
    }
  
    // Cleanup function to destroy the chart instance on unmount or before re-initializing
    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy();
        chartInstanceRef.current = null;
      }
    };
  }, [chartData, chartOptions]);
  
  return (
    <>
      {chartData && chartOptions && (
        <div style={{ width: '100%', height: '400px' }}>
          <canvas ref={canvasRef} />
        </div>
      )}
    </>
  );
};

export default BarGraphBoard;
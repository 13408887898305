/* eslint-disable */

import React, { useState, useEffect, useContext, useRef } from 'react';
import { AuthContext } from '../../../context/context';
import { setListItemsParsed } from '../helpers';

// Import Chart.js components for configuration
import {
    Chart as ChartJS,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(
    ArcElement,
    Tooltip,
    Legend
);

const PieChartBoard = (props) => {
    const {
        state: {
            integrations,
            integrationInfo,
            selectedIntegration,
            selectedBoard,
        },
        dispatch,
    } = useContext(AuthContext);

    const [chartData, setChartData] = useState(null);
    const [chartOptions, setChartOptions] = useState(null);

    const canvasRef = useRef(null);
    const chartInstanceRef = useRef(null);

    useEffect(() => {
        // Ensure props.board and props.board.boardData are defined before proceeding
        if (props.board && props.board.boardData) {
            setListItems(props.data, props.board.boardData, props.board.boardFormulas);
        }
    }, [props.board, props.data]);

    const setListItems = (allData, data, formulas) => {
        try {
            // Process data using helper function
            let result = setListItemsParsed(allData, data, formulas);

            if (result && result.length > 1) {
                const labels = result.slice(1).map((row) => row[0]); // Skip header and use first column as labels
                const dataValues = result.slice(1).map((row) => row[1]); // Second column as data

                setChartData({
                    labels: labels,
                    datasets: [
                        {
                            label: '', // Pie charts typically don't require a dataset label
                            data: dataValues,
                            backgroundColor: [
                                "rgba(43, 85, 133, 0.5)",
                                "rgba(128, 51, 100, 0.5)",
                                "rgba(75, 124, 92, 0.5)",
                                "rgba(50, 130, 40, 0.5)",
                                "rgba(12, 25, 54, 0.5)",
                                "rgba(115, 40, 89, 0.5)",
                                "rgba(78, 12, 99, 0.5)",
                                "rgba(7, 64, 58, 0.5)",
                                "rgba(152, 84, 72, 0.5)",
                                "rgba(33, 92, 55, 0.5)",
                                "rgba(21, 15, 41, 0.5)",
                                "rgba(118, 45, 108, 0.5)",
                                "rgba(14, 72, 140, 0.5)",
                                "rgba(90, 144, 73, 0.5)",
                                "rgba(151, 29, 87, 0.5)",
                                "rgba(130, 111, 15, 0.5)",
                                "rgba(97, 142, 103, 0.5)",
                                "rgba(56, 51, 117, 0.5)",
                                "rgba(40, 112, 88, 0.5)",
                                "rgba(121, 62, 19, 0.5)",
                                "rgba(138, 75, 93, 0.5)",
                                "rgba(102, 19, 62, 0.5)",
                                "rgba(24, 47, 75, 0.5)",
                                "rgba(51, 89, 13, 0.5)",
                                "rgba(134, 44, 50, 0.5)",
                                "rgba(75, 31, 150, 0.5)",
                                "rgba(47, 95, 125, 0.5)",
                                "rgba(65, 105, 57, 0.5)",
                                "rgba(100, 12, 82, 0.5)",
                                "rgba(144, 62, 110, 0.5)",
                            ],
                        },
                    ],
                });

                setChartOptions({
                    responsive: true,
                    maintainAspectRatio: false,
                    plugins: {
                        legend: {
                            position: 'top',
                            labels: {
                                usePointStyle: true,
                            },
                        },
                        title: {
                            display: false,
                            text: '',
                        },
                        tooltip: {
                            callbacks: {
                                label: function (context) {
                                    let label = context.label || '';
                                    let value = context.raw || 0;
                                    if (label) {
                                        label += ': ';
                                    }
                                    if (value !== null) {
                                        if (props.board.format === '$') {
                                            label += '$' + value.toLocaleString();
                                        } else {
                                            label += value.toLocaleString() + '%';
                                        }
                                    }
                                    return label;
                                },
                            },
                        },
                    },
                });
            } else {
                // Handle case where result is empty or has insufficient data
                setChartData(null);
                setChartOptions(null);
            }
        } catch (error) {
            console.error('Error in setListItems:', error);
            setChartData(null);
            setChartOptions(null);
        }
    };

    useEffect(() => {
        // Ensure Chart.js code runs only on the client side and canvas, data, and options are available
        if (typeof window !== 'undefined' && canvasRef.current && chartData && chartData.labels && chartOptions) {
          // Destroy existing chart instance if it exists to prevent duplication
          if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
          }
      
          // Initialize new chart instance
          chartInstanceRef.current = new ChartJS(canvasRef.current, {
            type: 'pie',
            data: chartData,
            options: chartOptions,
          });
        }
      
        // Cleanup function to destroy the chart instance on unmount or before re-initializing
        return () => {
          if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy();
            chartInstanceRef.current = null;
          }
        };
      }, [chartData, chartOptions]);
      
    return (
        <>
            {chartData && chartOptions && (
                <div style={{ width: '100%', height: '400px' }}>
                    <canvas ref={canvasRef} />
                </div>
            )}
        </>
    );
};

export default PieChartBoard;

/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import FreshBooks from '../../assets/freshbooks.png';
import QuickBooks from '../../assets/qb-logo.svg';
import Xero from '../../assets/xero.png';
import Spinner from '../../components/Workstation/Helpers/Spinner';
import { AuthContext } from '../../context/context';
import { getIntegrationInfo, deleteIntegrationInfo, validateToken } from '../../context/intuit-service';
import { useStyles } from './styles.js';

import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function ConnectedIntegrations(props) {
  const { state, dispatch } = useContext(AuthContext);
  const classes = useStyles();
  const [msg, setMsg] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [type, setType] = useState('success');
  const [openMsg, setOpenMsg] = useState(false);
  const [prog, setProg] = useState(true);
  const [connectedBooks, setConnectedBooks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);
  const [deletedOpen, setDeletedOpen] = useState(false);
  const [isTokenValid, setIsTokenValid] = useState(true);

  const handleOpen = (id) => setOpenModalId(id);
  const handleClose = () => setOpenModalId(null);
  const handleDeletedOpen = () => setDeletedOpen(true);
  const handleDeletedClose = () => setDeletedOpen(false);

  const getIcon = {
    "QuickBooks": QuickBooks,
    "Xero": Xero,
    "Freshbooks": FreshBooks,
  };

  const fetchIntegrationInfo = useCallback(async () => {
    setLoading(true);
    try {
      const books = await getIntegrationInfo();
      if (books && books.data) {
        if (books.data.integrations !== state.integrationInfo) {
          dispatch({
            type: 'SET_INTEGRATIONINFO',
            payload: books.data.integrations,
          });
        }
        if (books.data.integrations && Object.keys(books.data.integrations).length !== 0) {
          setConnectedBooks(books.data.integrations.integrations);
        } else {
          setConnectedBooks([]);
        }
      }
    } catch (error) {
      console.error("Error fetching integration info:", error);
      setConnectedBooks([]);
    } finally {
      setLoading(false);
    }
  }, [dispatch, state.integrationInfo]);

  const validateIntegrationToken = useCallback(async () => {
    if (!connectedBooks.length) return;

    const quickbooksIntegration = connectedBooks.find(book => book.integrationType === 'QuickBooks');
    if (!quickbooksIntegration) return;

    let data = {
      realmId: quickbooksIntegration.realmId,
      refresh_token: quickbooksIntegration.refresh_token,
      access_token: state.integrations?.quickbooks?.access?.token?.access_token || ''
    };

    try {
      const validatedToken = await validateToken(data);
      setIsTokenValid(validatedToken.status === 201);
      if (validatedToken.status === 201) {
        dispatch({
          type: 'SET_QUICKBOOK_AUTH',
          payload: {
            code: "",
            realmId: quickbooksIntegration.realmId,
            state: "",
            token: validatedToken.data.token,
          },
        });
        setAlertMessage('');
      }
    } catch (error) {
      console.error("Error validating token:", error);
      setAlertMessage("No connected integrations found.");
      setIsTokenValid(false);
    }
  }, [connectedBooks, dispatch, state.integrations]);

  useEffect(() => {
    fetchIntegrationInfo();
  }, [fetchIntegrationInfo]);

  useEffect(() => {
    validateIntegrationToken();
  }, [connectedBooks, validateIntegrationToken]);

  const deleteIntegration = useCallback(async (integration) => {
    setProg(false);
    try {
      const { protocol, hostname, port } = window.location;
      const URL = `${protocol}//${hostname}${port ? ':' + port : ''}`;
      const data = {
        integrationId: integration.integrationId,
        URL: URL
      };
      const response = await deleteIntegrationInfo(data);
      if (response && response.data) {
        if (response.data.result && response.data.result.integrations) {
          setConnectedBooks(response.data.result.integrations);
          dispatch({
            type: 'SET_INTEGRATIONINFO',
            payload: response.data.result,
          });
        }
      }
      dispatch({
        type: 'SET_SELECTEDINTEGRATION',
        payload: 0,
      });
      handleClose();
      handleDeletedOpen();
      window.open('https://www.datatrixs.com/quickbooks-disconnected', '_blank');
    } catch (error) {
      console.error("Error deleting integration:", error);
    } finally {
      setProg(true);
    }
  }, [dispatch]);

  return (
    <>
      {loading ? (
        <Spinner height={"100px"} />
      ) : (
        <>
          {alertMessage && !isTokenValid ? (
            <Typography variant="body1" color="error" align="left">
              {alertMessage}
            </Typography>
          ) : connectedBooks.length === 0 ? (
            <Typography variant="body1" color="error" align="left">
              No connected integrations found.
            </Typography>
          ) : (
            connectedBooks.map((book) => (
              <React.Fragment key={book.integrationId}>
                <Modal
                  open={openModalId === book.integrationId}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                      Are you sure you would like to disconnect this integration?
                    </Typography>
                    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                      <div
                        className="ui right red button"
                        onClick={() => deleteIntegration(book)}
                        style={{ cursor: 'pointer', marginRight: '10px' }}
                      >
                        Yes
                      </div>
                      <div
                        className="ui right button"
                        onClick={handleClose}
                        style={{ cursor: 'pointer' }}
                      >
                        No
                      </div>
                    </Typography>
                  </Box>
                </Modal>

                <div className="ui segments" style={{ marginBottom: '20px' }}>
                  <div className="ui segment" style={{ textAlign: 'left' }}>
                    <img
                      src={getIcon[book.integrationType]}
                      alt={`${book.integrationType} Logo`}
                      className="pr-5"
                      style={{ maxWidth: '200px' }}
                    />
                  </div>
                  <div className="ui segment">
                    <div className="row">
                      <div className="col-sm-3">
                        <p style={{ fontWeight: "bold" }}>Account Info</p>
                      </div>
                      <div className="col-sm-3">
                        <p>{book.email}</p>
                      </div>
                      <div className="col-sm-3">
                        <p>{new Date(book.date).toLocaleDateString()}</p>
                      </div>
                    </div>
                  </div>
                  <div className="ui segment" style={{ textAlign: 'left' }}>
                    <div
                      className="ui right red button"
                      onClick={() => handleOpen(book.integrationId)}
                      style={{ cursor: 'pointer' }}
                    >
                      Disconnect
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ))
          )}

          <Modal
            open={deletedOpen}
            onClose={handleDeletedClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                You're successfully disconnected from {connectedBooks.find((book) => book.integrationId === openModalId)?.book.integrationType}.
              </Typography>
            </Box>
          </Modal>
        </>
      )}
    </>
  );
}

export default ConnectedIntegrations;

/* eslint-disable */
import React, { useEffect, useState, useContext, useCallback } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Profile from '../../assets/profile.png';
import axios from '../../context/axios.js';
import { AuthContext } from '../../context/context.js';
import { changeIntegrationBoardName } from '../../context/intuit-service.js';

import LoadingBtn from '../Misc/LoadingBtn.js';
import InviteBoard from './InviteBoard.js';
import { useStyles } from './styles.js';

function Board(props) {
  const {
    state,
    dispatch,
  } = useContext(AuthContext);
  const { allBoards, purchasing } = state;
  const classes = useStyles();
  const index = props.index;
  const currentboardId = allBoards.boards[index]._id;

  const [boardName, setBoardName] = useState(allBoards.boards[index].boardName);
  const [msg, setMsg] = useState('');
  const [type, setType] = useState('success');
  const [openMsg, setOpenMsg] = useState(false);
  const [prog, setProg] = useState(true);
  const [file, setFile] = useState('');
  const [fileUrl, setFileUrl] = useState('');
  const [inviteData, setInviteData] = useState([]);
  const [totalEdits, setTotalEdits] = useState(3);
  const [currentEdits, setCurrentEdits] = useState(0);
  const [refreshFlag, setRefreshFlag] = useState(false);

  // Fetch Invited Users
  const getInvited = useCallback(async () => {
    try {
      const response = await axios.get('/getInvitesBoards');
      const data = response.data.data;
      if (data && data.length > 0) {
        const filteredData = data[0].invited.filter(invite => invite.boardId === currentboardId);
        setInviteData(filteredData);

        const modifyAccessEntries = data.flatMap(entry => entry.invited.filter(item => item.access === "CAN_MODIFY"));
        const distinctRecipientIds = [...new Set(modifyAccessEntries.map(item => item.recipientId))];
        setCurrentEdits(distinctRecipientIds.length);
      }
    } catch (error) {
      console.error('Error fetching invite data:', error);
    }
  }, [currentboardId]);

  // Update Board Settings
  const updateBoardSettings = useCallback(async () => {
    const data = {
      newName: boardName,
      board_id: currentboardId
    };
    try {
      // Clone allBoards to avoid direct state mutation
      let newBoards = { ...allBoards, boards: [...allBoards.boards] };
      for (let i = 0; i < newBoards.boards.length; i += 1) {
        if (newBoards.boards[i]._id === currentboardId) {
          newBoards.boards[i].boardName = boardName;
          break;
        }
      }
      const message = await changeIntegrationBoardName(data);
      if (message.status === 200) {
        dispatch({
          type: "SET_ALLBOARDS",
          payload: newBoards,
        });
      }
    } catch (error) {
      console.error('Error updating board settings:', error);
      // Optionally, set error state here to inform the user
    }
  }, [boardName, currentboardId, allBoards, dispatch]);

  // Delete User Invite
  const deleteUserInvite = useCallback(async (recipientId) => {
    setProg(false);
    try {
      const url = '/deleteUserInviteBoards';
      axios.defaults.headers.common.authorization = `${localStorage.getItem('finProtoken')}`;
      const res = await axios.post(url, { recipientId, boardId: currentboardId });
      setType('success');
      setMsg(res.data.message);
    } catch (err) {
      setType('error');
      if (err.response && err.response.data) {
        setMsg(err.response.data.message);
      } else {
        setMsg(err.message);
      }
    } finally {
      setProg(true);
      setOpenMsg(true);
      await getInvited();
    }
  }, [currentboardId, getInvited]);

  // Handle Access Change
  const handleAccessChange = useCallback(async (event, recipientId) => {
    const access = event.target.value;
    const boardId = currentboardId;
    const boardUserId = allBoards.userId;

    const previousInvite = inviteData.find(invite => invite.recipientId === recipientId);
    const previousAccess = previousInvite ? previousInvite.access : null;

    if (access === 'CAN_MODIFY' && previousAccess !== 'CAN_MODIFY') {
      setCurrentEdits(prev => prev + 1);
    } else if (access === 'CAN_VIEW' && previousAccess === 'CAN_MODIFY') {
      setCurrentEdits(prev => prev - 1);
    }

    try {
      const response = await axios.post('/modifyUserInviteBoards', {
        recipientId: recipientId,
        boardId: boardId,
        boardUserId: boardUserId,
        access: access
      });

       
      setInviteData(prevData =>
        prevData.map(invite =>
          invite.recipientId === recipientId ? { ...invite, access: access } : invite
        )
      );
    } catch (error) {
      console.error('Error updating invite access:', error);
      // Optionally, set error state here to inform the user
    }
  }, [currentboardId, allBoards.userId, inviteData]);

  // Initialize Board Name and Fetch Invites
  useEffect(() => {
    const initializeBoard = async () => {
      setBoardName(allBoards.boards[index].boardName);
      await getInvited();
      if (purchasing.planDetails.editors[0].quantity) {
        setTotalEdits(purchasing.planDetails.editors[0].quantity);
      }
    };
    initializeBoard();
  }, [allBoards.boards, index, getInvited, purchasing.planDetails.editors]);

  // Handle Refresh Flag
  useEffect(() => {
    const handleRefresh = async () => {
      if (refreshFlag) {
        await getInvited();
        if (purchasing.planDetails.editors[0].quantity) {
          setTotalEdits(purchasing.planDetails.editors[0].quantity);
        }
        setRefreshFlag(false);
      }
    };
    handleRefresh();
  }, [refreshFlag, getInvited, purchasing.planDetails.editors]);

  // Handle File Change
  const onChangeFile = (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onloadend = () => {
        setFileUrl(reader.result);
        setFile(selectedFile);
      };
    }
  };

  const hiddenFileInput = React.useRef(null);

  const handleClick = () => {
    hiddenFileInput.current.click();
  };

  // Render Invites
  const RenderInvites = useCallback((inviteData) => (
    <div>
      {inviteData.map((member) => (
        <div
          className="row"
          style={{ margin: 0 }}
          key={member.recipientId} // Ensure unique key
        >
          <div className="col-1">
            <img
              style={{
                width: '2.5rem',
                height: '2.5rem',
                borderRadius: '50%',
                marginRight: '2.5rem',
              }}
              src={member.profile ? member.profile : Profile}
              alt="Profile"
            />
          </div>
          <div className="col-8" style={{ marginLeft: '30px' }}>
            <div className="text-lowercase">
              {member.firstName && member.lastName && (
                <div className="row">
                  {`${member.firstName} ${member.lastName}`}
                </div>
              )}
              <div className="row">
                {member.recipientId}
              </div>
            </div>
          </div>
          <div className="col-1">
            <FormControl variant="standard" style={{ paddingTop: '2px' }}>
              <Select
                value={member.access}
                name="access"
                label="Access"
                onChange={(e) => handleAccessChange(e, member.recipientId)}
                disableUnderline
              >
                <MenuItem value="CAN_MODIFY">Editor</MenuItem>
                <ListSubheader>Can edit but not share with others.</ListSubheader>
                <MenuItem value="CAN_VIEW">Viewer</MenuItem>
                <ListSubheader>Cannot edit or share with others.</ListSubheader>
              </Select>
            </FormControl>
          </div>
          <div className="col-1">
            <LoadingBtn
              prog={prog}
              styleprop={classes.buttonStyle}
              onclick={deleteUserInvite}
              name="Delete"
              functiondata={member.recipientId}
            />
          </div>
        </div>
      ))}
    </div>
  ), [handleAccessChange, deleteUserInvite, prog, classes.buttonStyle]);

  return (
    <div className={classes.mainCard}>
      <h1> Client Settings </h1>
      <Card sx={{ width: '100%' }}>
        <CardContent style={{ padding: '50px 50px 50px 50px' }}>
          <div className="row">
            {allBoards?.boards && (
              <div className="col-6">
                <TextField
                  variant="standard"
                  style={{ paddingBottom: '15px', width: '100%' }}
                  onChange={(event) => setBoardName(event.target.value)}
                  required
                  label="Client name"
                  value={boardName}
                />
                <hr />
                <Button
                  style={{ width: '100%', backgroundColor: '#220FFE' }}
                  onClick={updateBoardSettings}
                  variant="contained"
                >
                  Update Client Name
                </Button>
              </div>
            )}
          </div>
        </CardContent>
      </Card>
      <div className="row" style={{ marginTop: '50px', width: '100%' }}>
        <div className="col-sm-10">
          <h3> Members </h3>
        </div>
        {allBoards && allBoards._id && (
          <div className="col-sm-2">
            <InviteBoard
              disabled={false}
              boardUserId={allBoards.userId}
              boardId={currentboardId}
              page={"Client_Setting"}
              refreshFlag={refreshFlag}
              setRefreshFlag={setRefreshFlag}
            />
          </div>
        )}
      </div>
      <hr />
      <div className="row" style={{ width: '100%' }}>
        {RenderInvites(inviteData)}
      </div>
    </div>
  );
}

export default Board;


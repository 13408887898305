/* eslint-disable */

import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import ListItemText from '@mui/material/ListItemText';
import Backdrop from '@mui/material/Backdrop'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import { makeStyles } from 'tss-react/mui';
import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { DropzoneArea } from 'material-ui-dropzone';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Icon } from 'semantic-ui-react';
import { AuthContext } from '../../context/context';
import { addNewBoard } from '../../context/intuit-service';
import AddTextModal from './AddTextModal';
import Assistant from './Assistant';
import { ListSubheader, Box } from "@mui/material";
import InsightsOutlinedIcon from '@mui/icons-material/InsightsOutlined';
import DonutLargeOutlinedIcon from '@mui/icons-material/DonutLargeOutlined';
import TextFieldsOutlinedIcon from '@mui/icons-material/TextFieldsOutlined';
import './style.css';

const PREFIX = 'AddViews';

const classes = {
  paper: `${PREFIX}-paper`,
  textField: `${PREFIX}-textField`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')(() => ({
  [`& .${classes.paper}`]: {
    padding: "24px",
    width: "100%",
    maxWidth: 800,
    maxHeight: 600,
    height: '100%',
    margin: "20px auto",
  },

  [`& .${classes.textField}`]: {
    marginTop: "16px",
  }
}));

const AddViews = (props) => {
    const {
        state: {
            user, switchUser, isAuthenticated, access_token, purchasing, projects, selectedBoard, allBoards, isFullScreen
        },
        dispatch
    } = React.useContext(AuthContext);

    //   paper: { minWidth: "400px" },
    //   // root: {
    //   //   display: "flex",
    //   //   justifyContent: "center",
    //   //   flexWrap: "wrap",
    //   //   "& > *": {
    //   //     margin: theme.spacing(0.5),
    //   //   },
    //   // },
    // }));

    const [anchorEl, setAnchorEl] = useState(null);
    const [addTextModalOpen, setAddTextModalOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [text, setText] = useState('Write about your data');
    const [addImageModalOpen, setAddImageModalOpen] = useState(false);
    const [generateModal, setGenerateModal] = useState(false);
    const [openBackDrop, setOpenBackdrop] = useState(false);

    const [hoverIndex, setHoverIndex] = useState(null);
    const handleMouseEnter = (index) => setHoverIndex(index);
    const handleMouseLeave = () => setHoverIndex(null);
    const [saving, setSaving] = useState(false)

    // New function to handle image button click
    const handleAddImageClick = () => {
        setAddImageModalOpen(true);
        handleClose();
    };

    // New function to handle image modal cancel
    const handleAddImageCancel = () => {
        setAddImageModalOpen(false);
    };

    // const handleCloseBackDrop = () => {
    //     setBackDropOpen(false);
    // };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleCloseBackdrop = () => {
        setGenerateModal(false);
        setOpenBackdrop(false);
    }

    const handleAddTextClick = () => {
        setAddTextModalOpen(true);
        handleClose();
    };

    const handleAddTextCancel = () => {
        setAddTextModalOpen(false);
    };

    const addText = async () => {
        // setSaving(true);


        // let boardInfo = {
        //     _id: allBoards.boards[selectedBoard]._id,
        //     boardId: allBoards._id,
        //     data: {
        //         index: allBoards.boards[selectedBoard].boards ? allBoards.boards[selectedBoard].boards.length : 0,
        //         boardView: "Text",
        //         boardType: "Text",
        //         title: title,
        //         boardData: {
        //             text: text
        //         },
        //     }
        // }
        // let addNewBoardResponse = await addNewBoard(boardInfo);


        // dispatch({
        //     type: 'SET_ALLBOARDS',
        //     payload: addNewBoardResponse.data.data
        // });
        // setSaving(false);
        // handleAddTextCancel();

    }

    const handleTitleChange = (event) => {
        setTitle(event.target.value);
    };

    const handleGenerateClick = () => {
        handleClose();
        // create backdrop
        setOpenBackdrop(true);
        // Load their quickbooks data

        setGenerateModal(true);
    }

    const navigate = useNavigate();

    const handleMenuItemClick = (path) => {
      navigate(path);
    };

    const useDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;
    const isSmallScreen = window.matchMedia('(max-width: 1023.5px)').matches;



    return (
      (<Root>
        <Box style={{ display: 'flex', marginBottom: '40px' }}>
          {props.newBoard ? (
            <Button
              className={`${isFullScreen ? 'hide-in-fullscreen' : ''} ui primary button mr-3`}
              style={{
                backgroundColor: "Black",
                width: "100px",
                height: "35px",
              }}
              onClick={handleClick}
            >
              Add
            </Button>
          ) : (
            <Box sx={{ width: '97%', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                className={isFullScreen ? 'hide-in-fullscreen' : 'third-step'}
                variant="text"
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start', // Align content to the left
                  whiteSpace: 'nowrap',
                  fontSize: '10px',
                  padding: '15px 0px 15px 10px', // Adjusted padding
                  width: '100%',
                  textAlign: 'left',
                  paddingBottom: '15px',
                  borderRadius: '10px',
                }}
                onClick={handleClick}
              >
                <AddIcon tyle={{ marginRight: '5px' }} />
                Add Content
              </Button>
            </Box>
          )}
        </Box>
        <Menu
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem
            onMouseEnter={() => handleMouseEnter(0)}
            onMouseLeave={handleMouseLeave}
            onClick={handleGenerateClick}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              color: hoverIndex === 0 ? 'green' : 'inherit',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <InsightsOutlinedIcon size="large" style={{ marginRight: "10px", color: hoverIndex === 0 ? 'green' : 'inherit' }} />
              <ListItemText
                className={classes.listItemText}
                primary="Mira"
                style={{ marginRight: "8px", color: hoverIndex === 0 ? 'green' : 'inherit' }}
              />
              <Chip size="small" label="Beta" />
            </div>
            <ListSubheader
              style={{
                marginTop: '-5px',
                paddingLeft: 31,
                backgroundColor: 'transparent',
                textDecoration: 'none',
              }}
            >
              Use natural language to generate charts with AI
            </ListSubheader>
          </MenuItem>

          <MenuItem
            onClick={() => handleMenuItemClick('/AddBoard')}
            onMouseEnter={() => handleMouseEnter(1)}
            onMouseLeave={handleMouseLeave}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              color: hoverIndex === 1 ? 'green' : 'inherit',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <DonutLargeOutlinedIcon
                size="large"
                style={{
                  marginRight: '10px',
                  color: hoverIndex === 1 ? 'green' : 'inherit',
                }}
              />
              <span
                style={{
                  textDecoration: 'none',
                  color: hoverIndex === 1 ? 'green' : 'black',
                }}
              >
                Chart
              </span>
            </div>
            <ListSubheader
              style={{
                marginTop: '-5px',
                paddingLeft: 31,
                backgroundColor: 'transparent',
                textDecoration: 'none',
              }}
            >
              Choose data and tell a visual story with charts
            </ListSubheader>
          </MenuItem>
          <MenuItem
            onMouseEnter={() => handleMouseEnter(2)}
            onMouseLeave={handleMouseLeave}
            onClick={handleAddTextClick}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
              color: hoverIndex === 2 ? 'green' : 'inherit',
            }}
          >
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <TextFieldsOutlinedIcon size="large" style={{ marginRight: "10px", color: hoverIndex === 2 ? 'green' : 'inherit' }} />
              <ListItemText primary="Text" style={{ color: hoverIndex === 2 ? 'green' : 'inherit' }} />
            </div>
            <ListSubheader
              style={{
                marginTop: '-5px',
                paddingLeft: 31,
                backgroundColor: 'transparent',
                textDecoration: 'none',
              }}
            >
              Use text to tell a story
            </ListSubheader>
          </MenuItem>
        </Menu>
        {generateModal ? (
          <>
            <Assistant
              assistantModalOpen={generateModal}
              assistantModalClose={() => setGenerateModal(false)}
            />
          </>
        ) : (
          <></>
        )}
        <AddTextModal
          addTextModalOpen={addTextModalOpen}
          handleAddTextCancel={handleAddTextCancel}
        />
        <Modal open={addImageModalOpen} onClose={handleAddImageCancel}>
          <div
            style={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "400px",
              height: "400px",
              backgroundColor: "#fff",
              borderRadius: "10px",
              padding: "20px",
              boxShadow: "0 0 10px rgba(0,0,0,0.3)",
            }}
          >
            <Typography variant="h4" gutterBottom>
              Add Media
            </Typography>
            <DropzoneArea
              onChange={(files) => console.log("Files:", files)}
              showPreviews={false}
              maxFileSize={5000000}
              accept="image/*"
            />
            <Button
              style={{
                paddingTop: "15px",
                width: "100%",
                backgroundColor: "#000000",
                color: "white",
              }}
              variant="contained"
            >
              Upload
            </Button>
          </div>
        </Modal>
      </Root>)
    );
}
export default AddViews;

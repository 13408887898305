/*eslint-disable */

import React, { useEffect, useState, useRef } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';
import addBoards from '../../assets/addBoards.png';
import noContent from '../../assets/undraw_empty_re_opql.svg';
import addContent from '../../assets/undraw_add_content_re_vgqa.svg';
import { AuthContext } from '../../context/context';
import BarGraphBoard from './BoardTypes/BarGraphBoard';
import ParsedDataTable from './BoardTypes/ParsedDataTable';
import AreaGraphBoard from './BoardTypes/AreaGraphBoard';
import LineGraphBoard from './BoardTypes/LineGraphBoard';
import PieChartBoard from './BoardTypes/PieChartBoard';
import EditBoardsButton from './EditBoardsButton';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { makeStyles } from 'tss-react/mui';
import 'react-grid-layout/css/styles.css';
import './style.css';
import 'react-resizable/css/styles.css';
import { saveLayout } from '../../context/intuit-service';
import AddViews from './AddViews';
import TextBoard from './BoardTypes/TextBoard';
import { extractId, extractPermission, formatDateRange, getIconType } from './helpers';
import AddTab from './AddTab';
import AddTemplate from './AddTemplate';
import GraphAssistantBoard from './BoardTypes/GraphAssistantBoard';
import { Line } from 'react-chartjs-2';
import DOMPurify from "dompurify";

const ResponsiveGridLayout = WidthProvider(Responsive);


const BoardsView = (props) => {
  const useStyles = makeStyles()(() => ({
    pageBreak: {
      pageBreakBefore: "always",
      breakBefore: "page",
    },
    pdfContent: {
      background: "white",
      width: "100%",
      height: "auto",
    },
    "@media print": {
      pageBreak: {
        display: "block",
        pageBreakBefore: "always",
        breakBefore: "page",
      },
    },
  }));
  const classes = useStyles();
  const {
    state: {
      integrations,
      integrationInfo,
      selectedIntegration,
      selectedBoard,
      allBoards,
      switchUser,
      selectedTab,
      isFullScreen,
    },
    dispatch,
  } = React.useContext(AuthContext);

  const [boardArray, setBoardArray] = useState([]);
  const chartInstances = useRef({});

  const addChartRef = (chartId, chartInstance) => {
    chartInstances.current[chartId] = chartInstance;
  };

  useEffect(() => {
    if (
      props.boards &&
      props.boards.boards &&
      props.boards.boards[selectedBoard] &&
      props.boards.boards[selectedBoard].boardData
    ) {
      renderBoards(props.boards.boards[selectedBoard].boardData);
    }
  }, [props]);

  useEffect(() => {
    if (isSaving) return;

     
    if (
      allBoards &&
      allBoards.boards &&
      allBoards.boards[selectedBoard] &&
      allBoards.boards[selectedBoard].tabs &&
      allBoards.boards[selectedBoard].tabs[selectedTab] &&
      allBoards.boards[selectedBoard].tabs[selectedTab].boardData
    ) {
      renderBoards(allBoards.boards[selectedBoard].tabs[selectedTab].boardData);
      console.log(
        "boardData: " +
          JSON.stringify(
            allBoards.boards[selectedBoard].tabs[selectedTab].boardData
          )
      );
    }
  }, [allBoards, selectedBoard, selectedTab]);

  const [loaded, setLoaded] = useState(false); // Add loaded state
  const [savedLayout, setSavedLayout] = useState(null);

  useEffect(() => {
    if (isSaving) return;

    let newSavedLayout = [];
    if (
      allBoards &&
      allBoards.boards &&
      allBoards.boards[selectedBoard] &&
      allBoards.boards[selectedBoard].tabs &&
      allBoards.boards[selectedBoard].tabs[selectedTab]
    ) {
      newSavedLayout =
        allBoards.boards[selectedBoard].tabs[selectedTab].layout || [];
    } else if (
      allBoards &&
      allBoards.boards &&
      allBoards.boards[selectedBoard] &&
      allBoards.boards[selectedBoard].layout
    ) {
      newSavedLayout = allBoards.boards[selectedBoard].layout || [];
    } else {
      newSavedLayout = [];
    }

    // Compare newSavedLayout with current savedLayout
    const isEqual =
      JSON.stringify(newSavedLayout) === JSON.stringify(savedLayout);
    if (!isEqual) {
      setSavedLayout(newSavedLayout);
      setLoaded(true);
    } else if (!loaded) {
      // Ensure loaded is set to true at least once
      setLoaded(true);
    }
  }, [allBoards, selectedBoard, selectedTab]);

  const checkAccess = () => {
    // if owner or you can modify then you have access to editing boards
    const access =
      (allBoards.boards[selectedBoard].access &&
        extractPermission(allBoards.boards[selectedBoard].access) ===
          "CAN_MODIFY") ||
      allBoards.boards[selectedBoard].owner === switchUser.userName;

    return access;
  };

  const renderBoards = (boards) => {
     
    let renderArray = [];
    for (let i = 0; i < boards.length; i = i + 1) {
      if (boards[i].boardType === "table") {
        renderArray.push({
          boardData: boards[i],
          board: (
            <ParsedDataTable
              board={boards[i]}
              data={props.fullDataTable}
              tableHeaders={props.tableHeaders}
            />
          ),
        });
      } else if (boards[i].boardType === "pie") {
        renderArray.push({
          boardData: boards[i],
          board: <PieChartBoard board={boards[i]} data={props.fullDataTable} />,
        });
      } else if (boards[i].boardType === "bar") {
        renderArray.push({
          boardData: boards[i],
          board: <BarGraphBoard board={boards[i]} data={props.fullDataTable} />,
        });
      } else if (boards[i].boardType === "line") {
        renderArray.push({
          boardData: boards[i],
          board: (
            <LineGraphBoard
              board={boards[i]}
              data={props.fullDataTable}
              headers={props.tableHeaders}
            />
          ),
        });
      } else if (boards[i].boardType === "area") {
        renderArray.push({
          boardData: boards[i],
          board: (
            <AreaGraphBoard
              board={boards[i]}
              data={props.fullDataTable}
              headers={props.tableHeaders}
            />
          ),
        });
      } else if (boards[i].boardType === "graph") {
         
        renderArray.push({
          boardData: boards[i],
          board: (
            <GraphAssistantBoard
              board={boards[i]}
              setRef={addChartRef}
              id={i}
            />
          ),
        });
      } else if (
        boards[i].boardType === "Text" ||
        boards[i].boardType === "text"
      ) {
        renderArray.push({
          boardData: boards[i],
          board: <TextBoard board={boards[i]} />,
        });
      }
    }
    setBoardArray(renderArray);
  };

  const handleDragEnd = (newLayout) => {
    if (loaded) {
      setSavedLayout(newLayout);
      handleSaveLayout(newLayout);
    }
  };

  const handleDragStop = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    // Only update savedLayout if it has changed
    if (JSON.stringify(savedLayout) !== JSON.stringify(layout)) {
      setSavedLayout(layout);
      handleSaveLayout(layout);
    }
  };

  const handleResizeStop = (
    layout,
    oldItem,
    newItem,
    placeholder,
    e,
    element
  ) => {
    const itemIndex = parseInt(newItem.i, 10);
    const board = boardArray[itemIndex];

    if (board?.boardData?.boardType === "Text") {
      const chartWidth = calculateChartWidth(newItem.w) - 15;
      const textContent = board?.boardData?.boardData[0]?.text || "";
      const newHeight = calculateTextBoardHeight(textContent, chartWidth);

      // Update the item's height in the layout
      const updatedLayout = layout.map((item) => {
        if (item.i === newItem.i) {
          return { ...item, h: newHeight };
        }
        return item;
      });

      // Only update savedLayout if it has changed
      if (JSON.stringify(savedLayout) !== JSON.stringify(updatedLayout)) {
        setSavedLayout(updatedLayout);
        handleSaveLayout(updatedLayout);
      }
    } else {
      // For non-text boards, update savedLayout with the new layout if it has changed
      if (JSON.stringify(savedLayout) !== JSON.stringify(layout)) {
        setSavedLayout(layout);
        handleSaveLayout(layout);
      }
    }
  };

  React.useEffect(() => {
     
  }, [boardArray]);

  const [isSaving, setIsSaving] = useState(false);
  const handleSaveLayout = async (layout) => {
    setIsSaving(true);
    let data = {
      sharedId: switchUser.userName,
      boardId: allBoards._id,
      clientId: allBoards.boards[selectedBoard]._id,
      tabId: allBoards.boards[selectedBoard].tabs[selectedTab]._id,
      layout: layout,
    };

    console.log("Saving layout:", JSON.stringify(layout)); // Log the data to ensure it's correctly formatted

    // If the user has 'CAN_MODIFY' access, override the boardId
    if (
      allBoards.boards[selectedBoard].access &&
      extractPermission(allBoards.boards[selectedBoard].access) === "CAN_MODIFY"
    ) {
      data.boardId = extractId(allBoards.boards[selectedBoard].access);
    }

    try {
      // Make the saveLayout API call
      const saveLayoutCall = await saveLayout(data);

      // If the save was successful
      if (saveLayoutCall.status === 200) {
        let newBoards = { ...allBoards };

        // Iterate over the boards to find and update the correct tab
        for (let i = 0; i < newBoards.boards.length; i++) {
          if (newBoards.boards[i]._id === allBoards.boards[selectedBoard]._id) {
            for (let j = 0; j < newBoards.boards[i].tabs.length; j++) {
              if (
                newBoards.boards[i].tabs[j]._id ===
                allBoards.boards[selectedBoard].tabs[selectedTab]._id
              ) {
                newBoards.boards[i].tabs[j].layout = layout;
                break;
              }
            }
            break;
          }
        }

        // Dispatch the updated boards to the state
        dispatch({
          type: "SET_ALLBOARDS",
          payload: newBoards,
        });
      } else {
        console.error("Save layout failed with status:", saveLayoutCall.status);
        console.error("Response:", saveLayoutCall);
      }
    } catch (error) {
      // Capture and log the error for debugging purposes
      console.error("Error saving layout:", error);
      if (error.response) {
        console.error("Response status:", error.response.status);
        console.error("Response data:", error.response.data);
      } else {
        console.error("Error message:", error.message);
      }
    }
    setIsSaving(false);
  };

  const tooltip1 = <Tooltip id="tooltip">Drag to Move</Tooltip>;

  const [hovered1, setHovered1] = useState(false);

  const [isHover, setIsHover] = useState(false);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);

  const [currentCols, setCurrentCols] = useState(12);
  const [currentBreakpoint, setCurrentBreakpoint] = useState("lg");
  const containerRef = useRef(null);

  const handleIconHover1 = () => {
    setHovered1(!hovered1);
  };

  const calculateChartWidth = (w) => {
    const containerWidth = containerRef.current?.clientWidth || 0;
    const margin = 23;
    const currentCols = 10;
    const totalMargins = margin * (currentCols - 1);
    const usableWidth = containerWidth - totalMargins;
    const colWidth = usableWidth / currentCols;
    return w * colWidth + margin * (w - 1);
  };

  const calculateTextBoardHeight = (
    htmlContent,
    chartWidth,
    fontFamily = "Helvetica",
    rowHeight = 115,
    lineHeight = "16px",
    bufferFactor = 1.05
  ) => {
    if (chartWidth <= 0) return 2;

    const offScreenDiv = document.createElement("div");
    //  

    offScreenDiv.style.position = "absolute";
    offScreenDiv.style.visibility = "hidden";
    offScreenDiv.style.width = `${chartWidth}px`;
    offScreenDiv.style.fontFamily = fontFamily;
    offScreenDiv.style.fontSize = "`14px";
    offScreenDiv.style.lineHeight = lineHeight;
    offScreenDiv.style.whiteSpace = "pre-wrap";
    offScreenDiv.style.wordBreak = "break-word";
    offScreenDiv.style.padding = "0";
    offScreenDiv.style.margin = "0";
    offScreenDiv.style.boxSizing = "border-box";
    offScreenDiv.style.fontWeight = "normal";
    offScreenDiv.style.fontStyle = "normal";

    offScreenDiv.innerHTML = `
    <style>
      * { margin: 0; padding: 0; box-sizing: border-box; }

      h1 { 
        font-size: 20px; 
        line-height: 28px; 
        margin: 0;
      }
      h2 { 
        font-size: 18px; 
        line-height: 24px; 
        margin-top: 0;
        margin-bottom: 0; 
      }
      h3 { 
        font-size: 14px; 
        line-height: 18px; 
        margin-top: 0;
        margin-bottom: 0; 
      }
      ul, ol { 
        margin: 0px 0 0px 0px; 
        padding: 0; 
      }
      li { 
        margin: 0px 0; 
        line-height: 10px; 
      }
      p { 
        margin: 0; 
        line-height: 5px; 
=      }
      section{
        margin: 0;
        line-height: 10px;
      }
    </style>
    ${DOMPurify.sanitize(htmlContent) || "No text available"}
    `;

    try {
      document.body.appendChild(offScreenDiv);
      const heightInPx = offScreenDiv.scrollHeight + 60;
      const bufferedHeight = heightInPx * bufferFactor;
      const heightInGridUnits = Math.ceil(bufferedHeight / rowHeight);
      return Math.max(heightInGridUnits, 2);
    } finally {
      document.body.removeChild(offScreenDiv);
    }
  };

  /**
   * Updates the boards and layout in the current selected tab with data from a template.
   * @param templateBoardData - An array of boardData objects carrying all of the static charts in the template.
   * @param templateLayout - An array of layout objects representing the template's layout configurations (how the charts from the templates are positioned).
   */
  const updateBoardsToTemplate = (templateBoardData, templateLayout) => {
    const newBoards = {...props.boards };
    const currentBoard = newBoards.boards[selectedBoard];
    const currentTab = currentBoard.tabs[selectedTab];

    // Update the board data with the template data
    currentTab.boardData = templateBoardData;

    // Update the layout with the template layout
    currentTab.layout = templateLayout;

    // Dispatch the updated boards to the state
    dispatch({
      type: "SET_ALLBOARDS",
      payload: newBoards,
    });
  };

  return (
    <>
      {props.boards.boards.length === 0 ? (
        <div className="pl-5 pr-5 pt-3 pb-3">Make a new Client!</div>
      ) : (
        <>
          {props.boards && (
            <div className="board-view">
              <AddTab />
              {props.boards &&
              props.boards.boards &&
              props.boards.boards.length > 0 &&
              props.boards.boards[selectedBoard] &&
              props.boards.boards[selectedBoard].tabs[selectedTab] &&
              props.boards.boards[selectedBoard].tabs[selectedTab].boardData &&
              props.boards.boards[selectedBoard].tabs[selectedTab].boardData
                .length === 0 ? (
                <>
                  {!props.disabled && (
                    <div className="pt-5 d-flex flex-column align-items-center">
                      {!isFullScreen && (
                        <>
                          <img
                            src={addContent}
                            style={{ width: "15%" }}
                            alt="Add Content"
                          />
                          <h2 className="mt-3">
                            Add a new chart or text to get started.
                          </h2>
                          <AddViews newBoard={true} />
                          {/* <AddTemplate
                            updateBoardsToTemplate={updateBoardsToTemplate}
                          /> */}
                        </>
                      )}
                      {isFullScreen && (
                        <>
                          <img
                            src={noContent}
                            style={{ width: "10%" }}
                            alt="Empty"
                          />
                          <h2 className="mt-3">Empty tab</h2>
                        </>
                      )}
                    </div>
                  )}
                </>
              ) : (
                <div className="container-fluid" style={{ width: "94%" }}>
                  <div className="row">
                    <div className="col-md-12">
                      <div
                        id="pdfExportContent"
                        className={classes.pdfContent}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        ref={containerRef}
                      >
                        {savedLayout !== null && (
                          <ResponsiveGridLayout
                            className=""
                            layouts={{
                              lg: savedLayout,
                              md: savedLayout,
                              sm: savedLayout,
                            }}
                            //onLayoutChange={handleDragEnd}
                            onDragStop={handleDragStop}
                            onResizeStop={handleResizeStop}
                            breakpoints={{
                              lg: 1200,
                              md: 900,
                              sm: 600,
                              xs: 480,
                              xxs: 0,
                            }}
                            cols={{
                              lg: 10,
                              md: 10,
                              sm: 10,
                              xs: 10,
                              xxs: 10,
                            }}
                            onBreakpointChange={(newBreakpoint, newCols) => {
                              setCurrentBreakpoint(newBreakpoint);
                              setCurrentCols(newCols);
                            }}
                            draggableHandle=".handle"
                            isResizable={checkAccess() ? isHover : false}
                            resizeHandles={["se"]}
                            isBounded={false}
                            useCSSTransforms={true}
                            transformScale={1}
                            autoSize={true}
                            rowHeight={115}
                            margin={[23, 23]}
                          >
                            {boardArray.map((board, i) => {
                              const isTextBoard =
                                board?.boardData?.boardType === "Text";
                              const layoutItem =
                                savedLayout.find(
                                  (item) => item.i === i.toString()
                                ) || {};

                              // console.log(
                              //   `${board.boardData.boardType} Board ${i} with height ${layoutItem.h} and width ${layoutItem.w}`
                              // );

                              return (
                                <div
                                  key={i}
                                  data-grid={{
                                    i: i.toString(),
                                    x:
                                      layoutItem.x !== undefined
                                        ? layoutItem.x
                                        : 0,
                                    y:
                                      layoutItem.y !== undefined
                                        ? layoutItem.y
                                        : Infinity,
                                    w: layoutItem.w || 12,
                                    h: layoutItem.h || 4,
                                    minW: 2,
                                    minH: isTextBoard ? 2 : 4,
                                  }}
                                >
                                  <div
                                    className="mb-5"
                                    style={{
                                      display: "grid",
                                      gridTemplateColumns: "10fr",
                                      width: "100%",
                                      height: "100%",
                                    }}
                                  >
                                    <div
                                      className={`ui ${
                                        isTextBoard ? "border-bottom" : "card"
                                      } pb-2`}
                                      style={{
                                        width: "100%",
                                        borderRadius: "10px",
                                        overflow: "hidden",
                                        height: "100%",
                                      }}
                                    >
                                      <div
                                        className={`header ${
                                          isTextBoard ? "pl-0" : ""
                                        }`}
                                      >
                                        <div className="handle">
                                          {((allBoards.boards[selectedBoard]
                                            .access &&
                                            extractPermission(
                                              allBoards.boards[selectedBoard]
                                                .access
                                            ) === "CAN_MODIFY") ||
                                            allBoards.boards[selectedBoard]
                                              .owner ===
                                              switchUser.userName) && (
                                            <OverlayTrigger
                                              placement="top"
                                              overlay={
                                                <Tooltip id={`tooltip-${i}`}>
                                                  Drag to Move
                                                </Tooltip>
                                              }
                                            >
                                              <span
                                                className="bi bi-grip-vertical"
                                                style={{
                                                  fontSize: "14px",
                                                  color: "grey",
                                                  cursor: "grab",
                                                  paddingLeft: "-20px",
                                                }}
                                              />
                                            </OverlayTrigger>
                                          )}
                                        </div>

                                        {board &&
                                          board.boardData &&
                                          board.boardData.boardView && (
                                            <div
                                              style={{
                                                display: "flex",
                                                flexDirection: "column",
                                              }}
                                            >
                                              <div style={{ display: "flex" }}>
                                                <p
                                                  style={{
                                                    fontSize: isTextBoard
                                                      ? "20px"
                                                      : "16px",
                                                  }}
                                                >
                                                  {board.boardData.title}
                                                </p>
                                              </div>
                                              <span
                                                style={{
                                                  fontSize: "14px",
                                                  paddingLeft: "0px",
                                                  color: "gray",
                                                  marginBottom: "10px",
                                                  marginTop: "5px",
                                                  whiteSpace: "nowrap",
                                                }}
                                              >
                                                {isTextBoard
                                                  ? null
                                                  : formatDateRange(props.date)}
                                              </span>
                                            </div>
                                          )}

                                        {!props.disabled &&
                                          props.boards &&
                                          props.boards.boards && (
                                            <div className="edit-button-container">
                                              <EditBoardsButton
                                                fullBoard={
                                                  props.boards?.boards?.[
                                                    selectedBoard
                                                  ]
                                                }
                                                board={
                                                  props.boards?.boards?.[
                                                    selectedBoard
                                                  ]?.tabs?.[selectedTab]
                                                    ?.boardData?.[i] ?? {}
                                                }
                                                getBoards={props.getBoards}
                                                boardId={props.boardId}
                                                boardType={
                                                  board?.boardData?.boardType
                                                }
                                              />
                                            </div>
                                          )}
                                      </div>

                                      <div
                                        className="content pt-2"
                                        style={{
                                          width: "100%",
                                          overflow: "hidden",
                                          overflowY: "auto",
                                          overflowX: "auto",
                                          height: "100%",
                                          paddingBottom: "70px",
                                        }}
                                      >
                                        {isTextBoard ? (
                                          <div
                                            className="content-container"
                                            style={{
                                              width: "100%",
                                              height: "100%",
                                            }}
                                          >
                                            <p
                                              style={{
                                                margin: "0",
                                                wordBreak: "break-words",
                                                whiteSpace: "pre-wrap",
                                              }}
                                              dangerouslySetInnerHTML={{
                                                __html:
                                                  DOMPurify.sanitize(
                                                    board?.boardData
                                                      ?.boardData[0]?.text
                                                  ) || "No text available",
                                              }}
                                            />
                                          </div>
                                        ) : (
                                          <div>{board.board}</div>
                                        )}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </ResponsiveGridLayout>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    {!props.disabled && <AddViews boards={props.boards} />}
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default BoardsView;

